type Amount = {
  [propName: string]: number[];
};

interface BuySellAmounts {
  amounts: Amount;
  defaultAmounts: Amount;
}

export const defaultCrypto: number[] = [50, 100, 200, 300, 500, 1000];
export const defaultFiat: number[] = [0.005, 0.01, 0.05, 0.1, 0.3];
export const defaultCustomCrypto: number[] = [500, 1000, 2000, 3000, 5000, 10000];

export const currencySymbols = {
  USD: '$',
  GBP: '£',
  JPY: '¥',
  EUR: '€',
  BTC: '₿',
  ETH: 'Ξ',
  LTC: 'Ł',
}

const buySellAmounts: BuySellAmounts = {
  amounts: {
    ETHBTC: [...defaultFiat],
    ETHUSD: [...defaultCrypto],
    XRPBTC: [...defaultFiat],
    XRPUSD: [...defaultCrypto],
    XRPETH: [...defaultFiat],
    BCHBTC: [...defaultFiat],
    BCHUSDC: [...defaultCrypto],
    BCHUSDT: [...defaultCrypto],
    LTCBTC: [...defaultFiat],
    BCHUSD: [...defaultCrypto],
    BTCUSD: [...defaultCrypto],
    BTCUSDT: [...defaultCrypto],
    BTCUSDC: [...defaultCrypto],
    LTCUSD: [...defaultCrypto],
    XLMBTC: [...defaultFiat],
    XLMUSDC: [...defaultCrypto],
    ETHUSDC: [...defaultCrypto],
    ETHUSDT: [...defaultCrypto],
    XRPUSDC: [...defaultCrypto],
    XRPUSDT: [...defaultCrypto],
    XLMUSD: [...defaultCrypto],
    XLMUSDT: [...defaultCrypto],
    LTCUSDC: [...defaultCrypto],
    LTCUSDT: [...defaultCrypto],
    USDTRSD: [...defaultCustomCrypto],
    USDCRSD: [...defaultCustomCrypto],
  },
  defaultAmounts: {
    fiat: [...defaultFiat],
    crypto: [...defaultCrypto],
  },
};

export default buySellAmounts;
