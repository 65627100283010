import React from 'react';
import companyLogo from '#images/logos/company-logo-alt.png';
import companyLogoDark from '#images/logos/company-logo.png';
import './logo.scss';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '#reducers/config';

const reloginLinks = {
  'en-us': 'https://www.crypto12.com/',
  'sr': 'https://www.crypto12.com/sr/',
}

const Logo = ({ isDark = false, isLoading = false }: { isDark?: boolean, isLoading?: boolean }) => {

  const currentLanguage = useSelector(selectCurrentLanguage);
  const link = reloginLinks[currentLanguage];

  const renderLogo = () => (
    <img src={!isDark ? companyLogo : companyLogoDark} alt="logo" />
  );

  return (
    <div className="logo-container">
      {isLoading
        ? renderLogo()
        : <a href={link} target="_blank">
          {renderLogo()}
        </a>
      }
    </div>
  )
};

export default Logo;

