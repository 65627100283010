import { KycProviders } from '#reducers/kyc/types';

export enum KycFormTypes {
  ManualKycForm = 'manual',
  WithTypesKycForm = 'withTypes',
}

export interface IKycConfig {
  formType: KycFormTypes,
  type: KycProviders,
}

export default {
  formType: KycFormTypes.ManualKycForm,
  type: KycProviders.Manual,
} as IKycConfig

export interface IKYCConstant {
  uploadImages: string[];
}

export const KYCConstant: IKYCConstant = {
  uploadImages: ['.jpeg', '.jpg', '.png', '.pdf']
};
