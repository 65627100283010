import { Auth, Amplify } from 'aws-amplify';
import awsconfig from './config/aws/awsconfig.json';
import awsauth from './config/aws/awsauth.json';
import { removeAllCookies } from './util/cookies';

export const removeStorage = () => {
  try {
    removeAllCookies();
    sessionStorage.clear();
  } catch {}
  Object.keys(window.localStorage).forEach((key) => {
    if (key && ['cognito', 'amplify'].some((_) => key.toLowerCase().indexOf(_) !== -1)) {
      console.log(key)
      window.localStorage.removeItem(key);
    }
  });
}

let initialized = false;
export default function initializeAmplify(domain, clientPoolId, poolId) {
  if (initialized) {
    return;
  }
  initialized = true;
  console.log('init amplify configs', poolId, clientPoolId, domain)
  const host = `${window.location.protocol}//${window.location.host}`;
  awsconfig.Auth.cookieStorage.domain = window.location.hostname;
  awsconfig.Auth.userPoolId = poolId;
  awsconfig.Auth.userPoolWebClientId = clientPoolId;
  awsauth.redirectSignIn = awsauth.redirectSignIn.replace('%host%', host);
  awsauth.redirectSignOut = awsauth.redirectSignOut.replace('%host%', host);
  awsconfig.Auth.redirectSignIn = awsauth.redirectSignIn;
  awsconfig.Auth.redirectSignOut = awsauth.redirectSignOut;
  awsauth.domain = domain;

  Amplify.configure({ ...awsconfig });
  Auth.configure({ awsconfig, storage: window.localStorage, oauth: { ...awsauth } });
}
