import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usePrevious } from "#/hooks/helpers";
import { RequestStatus } from "#/types";
import { selectAuthStatus } from "#reducers/user/user";
import { Paths } from "#/config/templates/nova/nova";

const RedirectEffect = () => {
  const authStatus = useSelector(selectAuthStatus);
  const navigate = useNavigate();
  const prev = usePrevious(authStatus);

  useEffect(() => {
    if (authStatus === RequestStatus.None && prev === RequestStatus.Success) {
      navigate(Paths.Login)
    }
  }, [authStatus]);

  return null;
}

export default RedirectEffect;
