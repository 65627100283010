import * as gql from 'gql-query-builder';

import {
  CreateDepositCryptoPayload,
  CreateWithdrawCryptoPayload,
  CreateWithdrawFiatPayload,
} from '../dto';
import {
  depositCryptoFields,
  paymentFields,
  fiatDepositBankFields,
} from '../fields';

export * from '../dto';

export enum DepositWithdrawOperations {
  CreateWithdrawCrypto = 'create_withdrawal_crypto',
  CreateWithdrawFiat = 'create_withdrawal_fiat',
  CreateDepositCrypto = 'deposit_address_crypto',
  CreateDepositFiat = 'deposit_bank_details_fiat',
  EstimateNetworkFee = 'estimate_network_fee',
  PaymentsRoutes = 'payments_routes',
}

export default {
  createDepositCrypto: (payload: CreateDepositCryptoPayload) => {
    return gql.query({
      operation: DepositWithdrawOperations.CreateDepositCrypto,
      variables: {
        currency_id: { value: payload.currency_id, required: true, type: 'String' },
        reference: { value: payload.reference, required: false, type: 'String' },
        payment_route_id: { value: payload.payment_route_id, required: true, type: 'String' }
      },
      fields: depositCryptoFields,
    });
  },

  createWithdrawCrypto: (payload: CreateWithdrawCryptoPayload) => {
    return gql.mutation({
      operation: DepositWithdrawOperations.CreateWithdrawCrypto,
      variables: {
        crypto_address: { value: payload.crypto_address, required: true, type: 'String' },
        amount: { value: payload.amount, required: true, type: 'Float' },
        crypto_address_tag_type: { value: payload.crypto_address_tag_type, required: false, type: 'CryptoAddressTagType' },
        crypto_network_fee_preference: { value: payload.crypto_network_fee_preference, required: false, type: 'CryptoNetworkFeePreference' },
        crypto_address_tag_value: { value: payload.crypto_address_tag_value, required: false, type: 'String' },
        mfa_token: { value: payload.mfa_token, required: false, type: 'String' },
        payment_route_id: { value: payload.payment_route_id, required: true, type: 'String' },
        fees_included: { value: payload.fees_included, required: false, type: 'ToggleSwitch' },
      },
      fields: paymentFields(),
    });
  },

  createWithdrawFiat: (payload: CreateWithdrawFiatPayload) => {
    return gql.mutation({
      operation: DepositWithdrawOperations.CreateWithdrawFiat,
      variables: {
        amount: { value: payload.amount, required: true, type: 'Float' },
        mfa_token: { value: payload.mfa_token, required: false, type: 'String' },
        properties: { value: payload.properties, required: true, type: '[FiatDepositPropertyInput!]' },
        payment_route_id: { value: payload.payment_route_id, required: true, type: 'String' },
      },
      fields: paymentFields(),
    });
  },


  getFiatDepositBankDetails: ({ payment_route_id }: { payment_route_id: string }) => {
    return gql.query({
      operation: DepositWithdrawOperations.PaymentsRoutes,
      variables: {
        payment_route_id: { value: payment_route_id, required: true, type: 'String' },
      },
      fields: fiatDepositBankFields,
    });
  },
}
